<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-04-19 08:47:43
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-13 15:47:29
-->
<template>
    <div class="luckyList">
        <div class="title-wrap">
            <el-page-header @back="goBack" content="中奖名单"></el-page-header>
        </div>
        <div class="case-wrap" v-loading="isloaidng">
            <div v-if="tableData.length>0">
                <div class="content">
                    <img class="image" :src="tableData[0].list[0].raffle_activity_prize.image" alt="">
                    <div class="title">{{tableData[0].list[0].raffle_activity_prize.name}}</div>
                </div>
                <div class="list" v-for="(data,index) in tableData" :key="index">
                    <div class="tit-w">
                        <div class="tit">第 {{data.batch}} 轮</div>
                        <div class="date">{{data.create_time}}</div>
                        <el-button @click="handleDelete(data)" size="mini" type="danger" plain>删除</el-button>
                    </div>
                    <el-table border :data="data.list" style="width: 100%;">
                        <el-table-column label="序号" width="100px" align="center">
                            <template slot-scope="scope">
                                {{ scope.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="奖品名称" prop="raffle_activity_prize.name"></el-table-column>
                        <el-table-column align="center" label="姓名" prop="activity_list_name"></el-table-column>
                        <el-table-column align="center" label="券码" prop="activity_list_mobile"></el-table-column>
                        <el-table-column align="center" label="时间" prop="create_time"></el-table-column>
                    </el-table>
                </div>
            </div>
            <div style="text-align: center;padding: 20px 0;font-size: 20px;color: #999;" v-else class="nofith">暂无数据</div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data () {
        return {
            tableData: [],
            isloaidng: false,
            id: ''
        }
    },
    mounted () {
        this.id = localStorage.getItem('gufId');
        this.getWinningIndex()
    },
    methods: {
        goBack(){
            this.$router.back() 
        },
        // 删除
        handleDelete(row){
            this.$confirm('此操作将永久删除该名单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    activity_prize_id: this.id,
                    batch: row.batch_id
                }
                common.connect("/rafflev1/activity/winningDelete",params,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getWinningIndex();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        getWinningIndex(){
            this.isloaidng = true
            let params = {
                activity_prize_id: this.id,
            }
            common.connect("/rafflev1/activity/winningIndex",params,(res)=>{
                this.tableData = res.data;
                this.isloaidng = false
                if(this.tableData == null){
                    this.tableData = []
                    this.isloaidng = false
                }
            });
        },
    },
}
</script>

<style scoped lang='scss'>
.luckyList{
    background: rgb(240, 242, 245);
    height: 100vh;
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 15px 25px;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px 20px 5px 20px;
        border-radius: 6px;
        position: relative;
        margin: 0 25px;
        .content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;
            .image{
                width: 60px;
                height: 60px;
                vertical-align: bottom;
            }
            .title{
                font-size: 20px;
                color: #000;
                margin-left: 20px;
            }
        }
        .list{
            padding-bottom: 20px;
            .tit-w{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 20px;
                .tit{
                    font-size: 20px;
                    color: #000;
                    font-weight: bold;
                }
                .date{
                    font-size: 16px;
                    color: #666;
                    margin: 0 20px;
                }
            }
        }
    }
    .nofith{
        width: 100%;
        text-align: center;
        padding: 20px 0;
        font-size: 20px;
        color: #999;
    }
}
</style>
